import React, { useCallback, useState, useMemo } from "react";
import { Nav, Row, Col, NavLink, NavItem, Container } from "reactstrap";
import instance from "../services/api";
import Preloader from "../components/Preloader";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { LabelRequired, ImageUpload } from "../components/formFields";
import { Title, ArrowButton } from "../components/common";

function Donate() {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("legal");
  console.log('====================================');
  console.log(type);
  console.log('====================================');
  const handleSubmit = useCallback(async (event, value) => {
    setLoading(true);

    await instance
      .post("/api/donator?", { ...value, type })
      .finally(() => setLoading(false));
  }, [type]);
  return (
    <div className="page-sale wrapper__container">
      <Container>
        <Title title="Նվիրատվություն" />
        <div className="donate">
          {loading && <Preloader className="overlay" />}
          <AvForm onValidSubmit={handleSubmit} model={{}} key={"form"}>
            <Nav className="donate-nav">
              <NavItem>
                <NavLink
                  className={type === "legal" ? "active" : ""}
                  onClick={() => {
                    setType("legal");
                  }}
                >
                  Իրավաբանական անձ
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={type === "phisical" ? "active" : ""}
                  onClick={() => {
                    setType("phisical");
                  }}
                >
                  Ֆիզիկական անձ
                </NavLink>
              </NavItem>
            </Nav>
            {type !== "phisical" ? (
              <>
                <Row>
                  <Col sm="12">
                    <AvField
                      name="company_type"
                      labelClass="label"
                      className="input"
                      required
                      label={
                        <LabelRequired>Կազմակերպության անվանում</LabelRequired>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="company_name"
                      labelClass="label"
                      className="input"
                      required
                      label={<LabelRequired> Անուն</LabelRequired>}
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      name="contact_person"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Ազգանուն</LabelRequired>}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="company_hvhh"
                      // errorMessage={''}
                      label={
                        <LabelRequired>Կազմակերպության ՀՎՀՀ</LabelRequired>
                      }
                      type="number"
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      type="number"
                      name="state_register_code"
                      // errorMessage={''}
                      label={<LabelRequired>Պետռեգիստրի կոդ</LabelRequired>}
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="legal_address"
                      // errorMessage={''}
                      label={<LabelRequired>Իրավաբանական հասցե</LabelRequired>}
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      name="activity_address"
                      // errorMessage={''}
                      label={
                        <LabelRequired>Գործունեության հասցե</LabelRequired>
                      }
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="email"
                      type="email"
                      // errorMessage={''}
                      label={<LabelRequired>Էլ․ հասցե</LabelRequired>}
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      name="phone"
                      // errorMessage={''}
                      label={<LabelRequired>Հեռախոս</LabelRequired>}
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <AvField
                      name="description"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Նկարագիր</LabelRequired>}
                      type="textarea"
                      rows="4"
                      required
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col sm={6}>
                    <AvField
                      name="contact_person"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Անուն</LabelRequired>}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <AvField
                      name="physical_person_email"
                      type="email"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Էլ․ հասցե</LabelRequired>}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <ImageUpload className="lg" url="donator" name="physical_person_document" />
                  </Col>
                  <Col sm={6}>
                    <AvField
                      name="phone"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Հեռախոս</LabelRequired>}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <AvField
                      name="description"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Նկարագիր</LabelRequired>}
                      type="textarea"
                      rows="4"
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs={12}>
                <AvField
                  name="incognito"
                  labelClass="label"
                  // className="input"
                  label={
                    <LabelRequired>Ցանկանում եմ մնալ անանուն</LabelRequired>
                  }
                  type="checkbox"
                  rows="4"
                  // requireds
                />
              </Col>
            </Row>
            <div className="form-footer">
              <ArrowButton text="Նվիրաբերել" />
            </div>
          </AvForm>
        </div>
      </Container>
    </div>
  );
}

export default Donate;
