import React, { useMemo } from "react";

function WrapperScroll({ Component }) {
    useMemo(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
  return <Component />;
}

export default WrapperScroll;
