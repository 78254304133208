import React, { useEffect, useState, useMemo } from "react";
import picture_one from "../assets/img/picture_one.png";
import ArrowUp from "../components/arrowUpAndDown/arrowUp";
import { request } from "../helperFunctions/requests";
import { useLocation } from "react-router-dom";

function Slider() {
  let location = useLocation();
  const [data, setData] = useState([]);

  let match = useMemo(() => {
    return window.location.pathname.substr(1).split('/')[0];
  }, [location]);

  const correctData = useMemo(() => {
    let result = [...data];
    if (!match.length) {
      const index = result.findIndex(item => item.page === '/');
      if (index !== -1) return result[index];
    } else {
      result = result.filter(item => item.page === match);
      if (result.length) return result[0];
    }
  }, [data, match]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/pages", {
        method: "GET",
      });
      setData(data);
    })();
  }, []);

  if (correctData?.image) {
    return (
      <div className={`slider-contant slider__home`}>
        <img src={correctData.image} />
        <ArrowUp />
      </div>
    );
  }
  return null;
}

export default Slider;
