import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function WhatIsCSOH() {
  const { info: {home_title = '', home_description = ''} = {} } = useSelector(state => state.test);
    
  return (
    <div className="csoh__contant wrapper__container">
      {/* <div className='csoh__wrapper all__container' > */}
      <Container className="csoh__wrapper">
        <Row className="csoh__titleWrapper">
          <div className="csoh__title">
            <span>{home_title}</span>
          </div>
        </Row>
        <Row className="csoh__text">
          <span>{home_description}</span>
        </Row>
        <Row className="csoh__button">
          <Link to="/goal">
            <div className="csoh__arrow arrow__component">
              <div className="arrow__component-body">
                <span>Իմանալ ավելին</span>
              </div>
            </div>
          </Link>
        </Row>
      </Container>
      {/* </div> */}
    </div>
  );
}

export default WhatIsCSOH;
