import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import instance from "../services/api";
import ArrowDown from "../components/arrowUpAndDown/arrowDown";

function Description() {
  const [data, setData] = useState({
    items: [],
  });
  const [dataInfo, setDataInfo] = useState({
    items: []
  });
  useEffect(() => {
    (async () => {
      await Promise.all([
        instance.get("/api/description"),
        instance.get("/api/description/citizen"),
      ]).then(
        ([
          { data: { data = {} } = {} },
          { data: { data: citizenData = {} } = {} },
        ]) => {
          setData(data);
          setDataInfo(citizenData);
        }
      );
    })();
  }, []);

  return (
    <div className="description__wrapper">
      <div className="description__contant wrapper__container">
        <Container>
          <Row className="description__title titleOne">
            <Col className="titleOne__wrapper">
              <span>Նկարագիր</span>
            </Col>
          </Row>
          <Row className="description__subtitle subtitle">
            <Col className="subtitle__text">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
            </Col>
          </Row>
          <Row className="description__bodyWrapper">
            <Col className="description__body">
              {data.items.map((item) => {
                return (
                  <Row key={item.id} className="description__itemWrapper item">
                    <Col>
                      <div className="item__wrapper">
                        <div className="item__textWrapper">
                          <p>{item.title}</p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="description__steps-contant wrapper__container">
        <ArrowDown />
        <Container>
          <Row>
            <Col className="description__steps-title">
              <p>{dataInfo.title}</p>
            </Col>
          </Row>
          <Row>
            <Col className="description__steps-body">
              {dataInfo.items.map((item, index) => {
                return (
                  <Row className="description__steps-item" key={item.id}>
                    <Col>
                      <div className="description__steps-itemWrapper">
                        <div className="description__steps-number">
                          <p>{index + 1}</p>
                        </div>
                        <div className="description__steps-text">
                          <p>
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Description;
