export function correctText(text, number) {
    let result = text;
    result = result.replace(/<.*?>/g, '');
    // result = result.substr(0, number);
    // let arr = result.split('');
    // const index = arr.lastIndexOf(' ');
    // result = result.substr(0, index);
    return result;
};

