import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { request } from "../helperFunctions/requests";
import { correctDate } from "../helperFunctions/correctDate";

function SingleNews() {
    const { id } = useParams();
    const [data, setData] = useState([]);

    const correctData = useMemo(() => {
        let result = [...data];
        result = result.filter((item) => item.id === +id);
        if (result.length) return result[0];
        return {};
    }, [data, id]);

    useEffect(() => {
        const controller = new AbortController();
        const abort = { signal: controller.signal };
        try {
            (async () => {
                const { data } = await request("/api/news", {
                    method: "GET",
                });
                setData(data);
            })();
        } catch (err) {
            console.log(err);
        }

        return () => controller.abort();
    }, []);

    return (
        <div className="singleNews wrapper__container">
            <Container>
                <Row className="reports__title titleOne">
                    <Col className="titleOne__wrapper">
                        <span>{correctData.title}</span>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="singleNews__img">
                            <img src={correctData.image} alt="" />
                        </div>
                    </Col>
                    <Col lg={7}>
                        <div className="singleNews__body">
                            <div className="singleNews__date">
                                {correctDate(correctData.date)}
                            </div>
                            <div className="singleNews__text">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: correctData.description,
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SingleNews;
