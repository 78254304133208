import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Map from "../components/map/map";
import { request } from "../helperFunctions/requests";
import { AddressIcon } from "../components/Icons";

function ReservedPoints() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const abort = { signal: controller.signal };
    try {
      (async () => {
        const { data } = await request("/api/storage", {
          method: "GET",
        });
        setData(data);
      })();
    } catch (err) {
      console.log(err);
    }

    return () => controller.abort();
  }, []);

  return (
    <div className="reservedPoints__wrapper">
      <div className="reservedPoints__contant-title wrapper__container">
        <Container>
          <Row className="reservedPoints__title titleOne">
            <Col className="titleOne__wrapper">
              <span>Պահեստային կետեր</span>
            </Col>
          </Row>
        </Container>
      </div>
      <Map data={data} />
      <div className="reservedPoints__contant-address wrapper__container">
        <Container>
          <Row>
            <Col>
              <table>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <span>{item?.region?.region_name}</span>
                        </td>
                        <td>
                          <div className="reservedPoints__address">
                            <div className="reservedPoints__address-img">
                              <AddressIcon className="reservedPoints__address-image" />
                            </div>
                            <div className="reservedPoints__address-info">
                              <p>ՀԱՍՑԵ`</p>
                              <p>{item?.street?.name}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ReservedPoints;
