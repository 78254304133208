const initialState = {
  data: [],
  contact: {
    tel: "",
    email: "",
    address: "",
  },
  info: {
    home_description: "",
    home_foundation: "",
    home_prepared: "",
    home_title: "",
    our_works_description: "",
  },
  social_icons: [],
};

const test = (state = initialState, { type, payload }) => {
  switch (type) {
    case "TEST":
      return {
        data: payload,
      };
    case "TEL":
      return {
        ...state,
        contact: payload,
      };
    case "INFO_AND_SOCIAL_ICONS":
      const { dataInfo, dataIcons } = payload;
      return {
        ...state,
        info: dataInfo,
        social_icons: dataIcons
      };
    default:
      return state;
  }
};

export default test;
