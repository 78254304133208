import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL: 'http://app.floopen.com',
});
instance.interceptors.response.use(
  (response) => {
    console.log('===================response=================');
    console.log(response);
    console.log('====================response================');
    if (response.config.method !== 'get' && response?.data?.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.config.method !== 'get' && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      toast.error(message);
    }
    return Promise.reject(error);
  }
);

export default instance;
