import React, { useState } from "react";
import {
  NavbarBrand,
  NavItem,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ArrowButton } from "./common";
import linkedInWhiteIcon from "../assets/img/icons-04-white.png";
import Slider from "./slider";
import Burger from "../assets/img/burger.jpg";
import logo from "../assets/img/logo.png";
import fbWhiteIcon from "../assets/img/icons-03-white.png";
import { useSelector } from "react-redux";
import { isAsyncMode } from "react-is";

const menuArr = [
  {
    menu: "Ծրագրի մասին",
    arr: [
      { name: "Նպատակ", link: "/goal" },
      { name: "Նկարագիր", link: "/description" },
      {
        name: "Առաջարկվող սոցիալական ծառայություններ",
        link: "/social_workers",
      },
      { name: "Հղումներ", link: "/links" },
    ],
  },
  {
    menu: "Նվիրաբերե’ք հիմա",
    arr: [
      { name: "Պահեստային կետեր", link: "/reserved-points" },
      { name: "Կոնտակտներ", link: "/contacts" },
      { name: "Մեր գործընկերները", link: "/partners" },
      { name: "Նվիրատուներ", link: "/donors" },
    ],
  },
  {
    menu: "Միացե’ք մեզ",
    arr: [
      { name: "Դառնալ կամավոր", link: "/volunteer" },
      { name: "Առաջիկա ակցիաներ", link: "/upcoming-actions/1" },
    ],
  },
  {
    menu: "Նորություններ",
    arr: [
      { name: "Վերջին նորություններ", link: "/upcoming-news" },
      { name: "Կազմակերպվող ակցիաներ", link: "/upcoming-actions/2" },
      { name: "Ամփոփ հաշվետվություն", link: "/reports" },
      { name: "Իրական պատմություններ", link: "/testimonials" },
    ],
  },
];

function Header() {
  const [active, setActive] = useState(false);
  const { contact: { tel = "" } = {}, social_icons = [] } = useSelector(
    (state) => state.test
  );
  return (
    <>
      <div className="header-content">
        <div className="header__up wrapper__container">
          <Container>
            <Row style={{height: '100%'}} >
              <Col>
                <div className="header__up-container all__container" style={{height: '100%'}} >
                  <div className="header__up-wrapper">
                    <div>
                      <div className="header__up-phoneIcon">
                        <i className="icon-phone"></i>
                      </div>
                      <div className="header__up-phone">
                        <a href={`tel:${tel}`}>
                          <span>{tel}</span>
                        </a>
                      </div>
                    </div>
                    <div>
                      {social_icons.map((item) => {
                        return (
                          <a href={item.link} target="_blank" key={item.id}>
                            <div className="header__up-fbIcon">
                              <img src={item.image} alt="" />
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <header className="app-header">
          <Navbar color="white" light expand="md" className="shadow-sm">
            <Container>
              <div className="logo-content">
                <NavbarBrand
                  to={`/`}
                  onClick={() => setActive(false)}
                  tag={Link}
                >
                  <div className="logo__wrapper">
                    <img src={logo} alt="logo" />
                  </div>
                </NavbarBrand>
                <div className="burger-menu" onClick={() => setActive(!active)}>
                  <img src={Burger} alt="burger" />
                </div>
              </div>
              <Nav navbar className={active ? "active" : ""}>
                {menuArr.map((item) => {
                  return (
                    <NavItem key={item.menu}>
                      <NavLink exact>
                        {item.menu}
                        {item?.arr?.length ? (
                          <Nav className="shadow-sm">
                            {item.arr.map((item) => {
                              return (
                                <NavItem key={item.link} key={item.name}>
                                  <Link
                                    to={item.link}
                                    onClick={() => setActive(false)}
                                    className="nav-link"
                                  >
                                    <span>{item.name}</span>
                                  </Link>
                                </NavItem>
                              );
                            })}
                          </Nav>
                        ) : null}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
              <ArrowButton text="Նվիրաբերել" to="donate" />
            </Container>
          </Navbar>
        </header>
      </div>
      <Slider />
    </>
  );
}

export default Header;
