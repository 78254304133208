import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PhoneIcon,
  Logo,
  AddressIcon,
  LinkdinIcon,
  FloopenIcon,
  FbIcon,
} from "./Icons";
import logo from "../assets/img/logo.png";
// import address from "../assets/img/address.png";
import phone from "../assets/img/icons-06.png";
import fbIcon from "../assets/img/icons-03.png";
import LInkdinIcon from "../assets/img/LInkdinIcon.png";
import floopen from "../assets/img/Floopen.png";
import UN_emblem from "../assets/img/UN_emblem.png";
import title from "../assets/img/title.png";
import { request } from "../helperFunctions/requests";
import { useSelector, useDispatch } from "react-redux";
import { handleContact } from "../actions/test";
import { Container, Row, Col } from "reactstrap";

const arr = [
  {
    name: "Կայքի քարտեզ",
    link: "/sitemap",
  },
  {
    name: "Հղումներ",
    link: "/links",
  },
  {
    name: "Հետադարձ կապ",
    link: "/contacts",
  },
  {
    name: "Հետևեք մեզ ֆեյսբուքում",
    link: "/fb",
  },
];
function Footer() {
  const dispatch = useDispatch();
  const {
    contact: { tel = "", address = "" } = {},
    info: { home_prepared = "", home_foundation = "" } = {},
    social_icons = [],
  } = useSelector((state) => state.test);

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/contact", {
        method: "GET",
      });
      dispatch(handleContact(data));
    })();
  }, [dispatch]);

  return (
    <div className="footer-contaent">
      <div className="footer__up wrapper__container">
        <Container>
          <Row>
            <Col>
              <div className="footer__up-wrapper all__container">
                <div className="footer__up-info">
                  <Link to="/">
                    <div className="footer__up-logoWrapper">
                      {/* <img src={logo} alt="" /> */}
                      <Logo />
                    </div>
                  </Link>
                  <div className="footer__up-infoWrapper">
                    <div className="footer__up-infoAdress">
                      <div className="footer__up-adressIcon">
                        {/* <img src={address} alt="" /> */}
                        <AddressIcon className="footer__up-adressIconImg" />
                      </div>
                      <div className="footer__up-adressText">
                        <span>{address}</span>
                      </div>
                    </div>
                    <div className="footer__up-infoPhone">
                      <div className="footer__up-phoneIcon">
                        {/* <img src={phone} alt="" /> */}
                        <PhoneIcon className="footer__up-phoneIconImg" />
                      </div>
                      <div className="footer__up-phoneText">
                        <a href={`tel:${tel}`}>
                          <span>{tel}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="footer__up-socialIcons">
                    {social_icons.map((item) => {
                      return (
                        <a href={item.link} target="_blank" key={item.id}>
                          <div className="footer__up-icon">
                            <img src={item.image} alt="" />
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div className="footer__up-menus">
                  <div className="footer__up-menusWrapper">
                    <div className="footer__up-menusList">
                      <ul>
                        <div>
                          {arr.map((item, index) => {
                            return (
                              <li key={index}>
                                <Link to={item.link}>
                                  <span>{item.name}</span>
                                </Link>
                              </li>
                            );
                          })}
                        </div>
                        <Link to="/donate">
                          <div className="footer__up__down-arrow arrow__component">
                            <div className="arrow__component-body">
                              <span>Հանգանակել</span>
                            </div>
                          </div>
                        </Link>
                      </ul>
                    </div>
                    <div className="footer__up-logosWrapper">
                      <div className="footer__up-logos">
                        <div className="footer__up-infoText">
                          <span>{home_prepared}</span>
                        </div>
                        <a href={"/"} target="_blank">
                          <div className="footer__up-logoOne">
                            <img src={UN_emblem} alt="" />
                          </div>
                        </a>
                        <a href={"http://www.mlsa.am/"} target="_blank">
                          <div className="footer__up-logoTwo">
                            <img src={title} alt="" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer__down wrapper__container">
        <Container>
          <div className="footer__down-wrapper all__container">
            <div className="footer__down-info">
              <span>{home_foundation}</span>
            </div>
            <div className="footer__down-logo">
              {/* <img src={floopen} alt="" /> */}
              {/* <FloopenIcon /> */}
              <p>
                Website by{" "}
                <a href={"https://floopen.com/"} target="_blank">
                  <span>
                    FL<span>OO</span>PEN
                  </span>
                </a>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
