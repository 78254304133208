import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { request } from "../helperFunctions/requests";
import { correctDate } from "../helperFunctions/correctDate";
import { Link, useParams } from "react-router-dom";
import OrganizedActionsItem from "../components/organized/OrganizedActions";
import Pagination from "../components/pagination";

const pageDataCount = 9;

function UpComingAkcia() {
  const { type } = useParams();
  const [actions, setActions] = useState(type);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const controller = new AbortController();
    const abort = { signal: controller.signal };

    try {
      (async () => {
        const { data } = await request(
          `/api/action/${actions === "1" ? "upcoming" : "organized"}`,
          {
            method: "GET",
          }
        );
        setData(data);
      })();
    } catch (err) {
      console.log(err);
    }

    return () => controller.abort();
  }, [actions]);

  useMemo(() => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}, [pageNumber]);

const correctData = useMemo(() => {
    let result = [...data];
    let arr = [];
    result.forEach((item, index) => {
        const pageNumber = Math.ceil((index + 1) / pageDataCount);
        arr.push({
            page: pageNumber,
            data: item
        })
    });
    arr = arr.filter(item => item.page === pageNumber);
    return arr;
}, [data, pageNumber]);

const hnadleChangePageNumber = useCallback((id) => {
    setPageNumber(id);
}, []);

  return (
    <div className="upComingAkcia__contant wrapper__container">
      <Container>
        <Row className="upComingAkcia__title titleOne">
          <Col className="titleOne__wrapper">
            <span>Ակցիաներ</span>
          </Col>
        </Row>
        <Row className="socialWorker__menusType">
          <Col className="socialWorker__menu" lg={1}>
            <div className="socialWorker__menuWrapper">
              <p
                className={`${actions === "1" ? "active" : ""}`}
                onClick={() => setActions("1")}
              >
                <span>Առաջիկա ակցիաներ</span>
              </p>
              <p
                className={`${actions === "2" ? "active" : ""}`}
                onClick={() => setActions("2")}
              >
                <span>Ակցիաներ</span>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="upComingAkcia__wrapper handle__row25">
          {correctData.map(({data:item}) => {
            return (
              <Col
                key={item.id}
                className="upComingNews__cardsWrapper handle__col25"
                lg={4}
                md={12}
                sm={12}
              >
                <OrganizedActionsItem
                  id={item.id}
                  img={item.image}
                  date={correctDate(item.date)}
                  title={item.title}
                  text={item.description}
                  button={actions !== "2" ? true : false}
                />
              </Col>
            );
          })}
        </Row>
        <Pagination
          dataCount={data.length}
          pageData={pageDataCount}
          pageNumber={pageNumber}
          hnadleChangePageNumber={hnadleChangePageNumber}
        />
      </Container>
    </div>
  );
}

export default UpComingAkcia;
