export function Years() {
    const thisYear = new Date().getFullYear();
    const arr = [];
    for (let i = 1930; i <= thisYear + 5; i++) {
        arr.push({ id:i, name: i});
    }
    return arr;
}
export function Month() {
  const month = [
    {
      id:'01',
      name:'Հունվար',
    },
    {
      id:'02',
      name:'Հունվար',
    },
    {
      id:'03',
      name:'Մարտ',
    },
    {
      id:'04',
      name:'Ապրիլ',
    },
    {
      id:'05',
      name:'Մայիս',
    },
    {
      id:'06',
      name:'Հունիս',
    },
    {
      id:'07',
      name:'Հուլիս',
    },
    {
      id:'08',
      name:'Օգոստոս',
    },
    {
      id:'09',
      name:'Սեպտեմբեր',
    },
    {
      id:'10',
      name:'Հոկտեմբեր',
    },
    {
      id:'11',
      name:'Նոյեմբեր',
    },
    {
      id:'12',
      name:'Դեկտեմբեր',
    },
  ]
  return month;
}
export function Days() {
  const dayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
  const days = [];

  for (let index = 1; index < dayOfMonth.getDate(); index++) {
    days.push({
      id: index,
      name: index
    })

  }
  return days;
}
