import { toast } from "react-toastify";

export function request(url, data = {}) {
    const {
        method = "POST",
        body = {},
        headers = {
            "Content-Type": "application/json",
        },
    } = data;
    if (method !== "GET") {
        data.body = body;
    }
    return fetch(`http://app.floopen.com${url}`, {
        ...data,
        method,
        headers,
    }).then(async (res) => {
        const result = await res.json();
        if (res.ok) {
          if(method !== "GET"){
            toast.success(result.message);
          }
          return result;
        }
        toast.error(result.message);
    });
}
